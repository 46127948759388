import React from 'react';


// Import team photos
import AvPhoto from "./teamPhotos/AvPhoto.jpg";
import BsPhoto from "./teamPhotos/BsPhoto.jpg";
import EnPhoto from "./teamPhotos/EnPhoto.png";
import FlPhoto from "./teamPhotos/FlPhoto.jpg";
import FoPhoto from "./teamPhotos/AgPhoto.png";
import MaPhoto from "./teamPhotos/MaPhoto.jpg";
import SaPhoto from "./teamPhotos/SaPhoto.jpg";
import VePhoto from "./teamPhotos/VePhoto.jpg";



// allows us to split text into pharagraphs rather than one bing chunk 
const businessDescriptionLong = "The Business team serves to cultivate FRL's brand, whether that be through corporate relations or university networking. We are responsible for all sponsorship concerns, company outreach, and internship sourcing. \n It is our goal to facilitate all members of FRL such that they have all the resources they require to succeed. \n In the last two years, we managed the rebrand of UF Liquid Propulsion (now FRL) and secured funding to purchase the team's test stand trailer. We also represented FRL at multiple conferences, drove the development of floridarocketlab.org, and instituted several programs to assist in securing in-kind material donations from aerospace suppliers. ";
const avionicsDescriptionLong = "The Avionics subteam handles everything involving software and hardware in the Florida Rocket Lab. This includes our custom built \"Sparrow\" test stand system, designed from scratch to allow for precise measurements and configurable controls. \n In the future, this team will continue to develop it's hardware skills as the Florida Rocket Lab competes in the Collegiate Propulsive Lander Competition (CPLC), with the development of custom Avionics systems."
const engineDescriptionLong = "The Engine Design subteam is responsible for everything in between where the propellants enter the injector and the supersonic exhaust coming out of the nozzle. This encompasses things like injector and manifold design, thrust chamber design, ignition systems, heat transfer, computational fluid dynamics, finite element analysis, mechanical design, transport phenomena, and much more. \n We adhere to a design developmental lifecycle starting with a systems requirements review (SRR), followed by a preliminary design review (PDR), and finally a critical design review (CDR).";
const fluidsDescriptionLong = "Our team oversees the design and construction of the fluid systems for our rocket engine test stands and the hopper for the Collegiate Propulsive Landing Competition. Our goal is to deliver fuel and oxidizer to the engine at the specified mass flow rate. \n We manage high-pressure operations, control valves, transient modeling, and oxygen cleaning. Members get both design and hands-on experience with pneumatic/electric valves, tubing, and fittings. \n In the past, we have designed and built out the test stand for the GOX/GCH4 igniter. We are currently working on the test stand for the Sparrow which is N2O/IPA.";
const manufacturingDescriptionLong = "Our manufacturing team cooperates with all other sub-teams to efficiently create all required parts from various materials. \n Our manufacturing team's primary responsibilities include researching manufacturing techniques, solid model and drawing generation, and experience with manual and CNC machining. \n We use industry-grade mills, lathes, and CNC machines to prototype and manufacture almost all of our engine parts in-house.";
const safetyDescriptionLong = "The safety subteam specializes in all items related to mission assurance. With key functions split into Fault Management, Systems Safety, and Test Ops Support, we ensure that all systems developed at FRL are designed with safety and reduced risk in mind.\n Members start on other subteams and get nominated to become a safety representative. Safety representatives are responsible for the analysis and mitigation of potential hazards and failures in our systems, as well as on-pad ground support during test days. \n Safety representatives can expect to become well-versed in industry & military standards as well as hands-on experience operating high performance rocket systems.";
const vehicleDescriptionLong = "The Vehicle Engineering team is currently designing Hopper: a VTVL (Vertical Takeoff, Vertical Landing) flight vehicle that is propelled by the Sparrow rocket engine. \n Taking inspiration from lunar landers, past VTVLs, and Florida Rocket Lab engineering intuition, Hopper is a groundbreaking student achievement focused on stability and cost efficiency. \n The three Vehicle Engineering subteams: Structures; Propellant Tanks; and Guidance, Navigation & Control are working tirelessly to design the vehicle, prototype and manufacture in-house spherical propellant tanks, and develop a proprietary control system to govern the thrust vectoring of the engine during flight.";
 
    function createParagraphs(text) {
        return text.split('\n').map((item, index) => (
          <React.Fragment key={index}>
            {item}
            <br />
            </React.Fragment>
        ));
      }
      
const subteams = {
    // Avionics, Business, Engine, Fluids, Food, Manufacturing, Safety, Vehicle
    Avionics: {
        name: "Avionics",
        photo: AvPhoto,
        text: createParagraphs(avionicsDescriptionLong) ,
        lead: "Chase Macdonnell",
        members: "Aryan Chand, Blake Crawford, Dylan Butera, Jose Reynaldo, Matias Gast"
    },
    Business: {
        name: "Business",
        photo: BsPhoto,
        text: createParagraphs(businessDescriptionLong),
        lead: "Nathan O'Brien",
        members: "Jack Lobeck, Grant Sherman, Jonner Delgado, Austin Martin"
    },
    Engine: {
        name: "Engine Design",
        photo: EnPhoto,
        text: createParagraphs(engineDescriptionLong),
        lead: "Austin Martin",
        members: "Nikhil Chitturi, Andy Rodriguez, CJ Czepiel, Santiago Hatadis, Liam Beaubien, Ryan Shimek, Oleksandr Dorotych, Alexander Bowman, Miles Moerman, Juliet McDaniel, Matthew Krininger, Anderson Kruize, Matias Martinez, Orion Snow, Andres Villate, Alexander Edlin, Nate Stairs, Miguel Normey, Anais Mera-Sarnelli"
    },
    Fluids: {
        name: "Fluids",
        photo: FlPhoto,
        text: createParagraphs(fluidsDescriptionLong),
        lead: "Daniel Hoffman",
        members: "Sydney Chin, Mailee Burke, Jackson Banks, Zane Carter, Luke Babin, Benjamin Smith, Jacob Klein, Pablo Coral, Joshua Valencia, Mario Bovero, Daniel Borras Quintero, Marie Klinzing"
    },
    Agriculture: {
        name: "Agriculture",
        photo: FoPhoto,
        text: "The food and agriculture subteam aims to contract a series of raised beds which will be used both to grow food, as well as to beautify our space in the Solar Park. The food and agriculture subteam offers a more recreational environment for members of the team to discuss gardening, cooking, and food in.",
        lead: "Alexander Bowman ",
        members: "N/A"
    },
    Manufacturing: {
        name: "Manufacturing",
        photo: MaPhoto,
        text: createParagraphs(manufacturingDescriptionLong),
        lead: "Nicholas Pech",
        members: "Adrian Costa, Andy Rodriguez, Ashley Backus, Austin Martin, Jack Parker, Kevin Nguyen, Nicholas Hirsch, Pablo Coral, Robert Kaufman"
    },
    Safety: {
        name: "Safety",
        photo: SaPhoto,
        text: createParagraphs(safetyDescriptionLong),
        lead: "Lucca Fogaca",
        members: "Mandy Tran, Santiago Hatadis, Sydney Chin, Christopher Broski, Miguel Normey, Jayden Noel, Peter Chandler"
    },
    Vehicle: {
        name: "Vehicle",
        photo: VePhoto,
        text: createParagraphs(vehicleDescriptionLong),
        lead: "John Bromfield",
        members: "Peter Chandler, Nicholas Carter, Sasha Gorbanovski, Junwei Tan, Benjamin Smith, Jayden Noel, Tyler Pencinger, Garret Kauzlarich, Nicholas Hirsch, Jonah Bennet, Miguel Normey, Benjamin Smith"
    }
}

export default subteams;