import React, { useState } from 'react';
import "./Projects.css";
import Footer from '../Components/Footer';
import Modal from '../Components/Modal'; 
import igniteV from './IGHOT.mp4';
import subteams from './Subteams';
import trailer1 from '../Images/Trailer/trailer_outside.png'
import trailer2 from '../Images/Trailer/trailer_p1.png'
import trailer3 from '../Images/Trailer/trailer_p2.png'
import trailer4 from '../Images/Trailer/trailer_p3.png'
import trailer5 from '../Images/Trailer/trailer_lone.png'
import trailer6 from '../Images/Trailer/trailer_party.png'
import sparrow1 from '../Images/Sparrow/Engine Iso.png'
import sparrow2 from '../Images/Sparrow/Engine Transparent.png'
import sparrow3 from '../Images/Sparrow/sparrow_apart.png'

// import { Typography } from '@mui/material';

const ProjectsPage = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    // Subteam Modal Stuff
    const [teamModal, setTeamModal] = useState(false);
    const [teamPhoto, setTeamPhoto] = useState(subteams.Avionics.photo);
    const [teamDescription, setTeamDescription] = useState(subteams.Avionics.text);
    const [teamMembers, setTeamMembers] = useState(subteams.Avionics.members);
    const [teamName, setTeamName] = useState(subteams.Avionics.name);
    const [teamLead, setTeamLead] = useState(subteams.Avionics.lead);

    if (teamModal) {
        document.body.classList.add('active-modal')
      } else {
        document.body.classList.remove('active-modal')
      }
    // End of Subteam Modal Stuff

    const engineDescriptionLong = `Sparrow V1 is Florida Rocket Lab's newest liquid rocket engine. Designed for 500 lbs of thrust at a chamber pressure of 450 psi using isopropanol and nitrous oxide, it is the most advanced engine ever developed at the University of Florida. \n The engine is a chamber, saddle, jacket (CSJ) design, which allows us to manufacture the engine on campus as opposed to using outsourced additive manufacturing. Sparrow V1 utilizes a pressure-fed pintle injector and a water-cooled copper combustion chamber. This engine is a precursor to the more advanced Sparrow V2, which will be fully regeneratively cooled and used on our hopper vehicle as part of the Collegiate Propulsive Lander Challenge (CPLC).`;

    function createParagraphs(text) {
        return text.split('\n').map((item, index) => (
          <React.Fragment key={index}>
            {item}
            <br />
          </React.Fragment>
        ));
      }

    const projects = [
        {
            title: "Sparrow Engine",
            description: "Igniter Test",
            descriptionLong: createParagraphs(engineDescriptionLong),
            mediaType: "video",
            mediaUrl: igniteV,
            slideImages: [sparrow1, sparrow2, sparrow3]
        },
        {
            title: "Trailer Testbed",
            description: "Full Body Picture",
            descriptionLong: "The \"Test Stand Trailer\" is a large frame of steel, tubing, and electronics that allows the Florida Rocket Lab to test it's current engine: Sparrow; as we'll as future engines however large they may be. This trailer was designed specifically for the current and future needs of the team, with an easy to program electrical system, mounting points for fluid hardware, and a large blast wall to protect all of our sensitive equipment. Through the hard work of our many members, this trailer just recently went from a design to a reality, and we are excited for what it means for our future!",
            mediaType: "image",
            mediaUrl: trailer1,
            slideImages: [trailer1, trailer2, trailer3, trailer4, trailer5, trailer6]
        }
    ];

    const openModal = (index) => {
        if (index >= 0 && index < projects.length) {
            setSelectedProject(projects[index]);
            setModalOpen(true);
        }
    };

    const closeModal = () => {
        setSelectedProject(null);
        setModalOpen(false);
    };

    return (
        <div>
            <div className="Hero2">
                <a className="HeroHeader2 extraSpace">Our Projects</a>
            </div>
            <div className="projects-page">
                {projects.map((project, index) => (
                    <div key={index} className="project-card" onClick={() => openModal(index)}>
                        {project.mediaType === "image" && <img src={project.mediaUrl} alt={project.title} />}
                        {project.mediaType === "video" && (
                            <video src={project.mediaUrl} muted autoPlay playsInline loop>
                                <source src={project.mediaUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        {project.mediaType === "youTube" && (
                            <iframe 
                                width="100%"
                                height="auto"
                                src={project.mediaUrl}
                                title={project.title}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        )}
                        <h2>{project.title}</h2>
                        <p>{project.description}</p>
                    </div>
                ))}
            </div>
            <div className="subteams-page">
                <h1 className="subteams-header">Subteams</h1>
                <div className="subteams-container">
                    <div className="subteam-content">
                        <img src={subteams.Avionics.photo} className="subteam-photo" onClick={() => {setTeamModal(!teamModal); setTeamDescription(subteams.Avionics.text); setTeamPhoto(subteams.Avionics.photo); setTeamMembers(subteams.Avionics.members); setTeamLead(subteams.Avionics.lead); setTeamName(subteams.Avionics.name)}}></img>
                        <p className="subteam-name">{subteams.Avionics.name}</p>
                        <p className="subteam-lead">Director: {subteams.Avionics.lead}</p>
                        <p className="subteam-members">Members: {subteams.Avionics.members}</p>
                    </div>
                    <div className="subteam-content">
                        <img src={subteams.Business.photo} className="subteam-photo" onClick={() => {setTeamModal(!teamModal); setTeamDescription(subteams.Business.text); setTeamPhoto(subteams.Business.photo); setTeamMembers(subteams.Business.members); setTeamLead(subteams.Business.lead); setTeamName(subteams.Business.name)}}></img>
                        <p className="subteam-name">{subteams.Business.name}</p>
                        <p className="subteam-lead">Director: {subteams.Business.lead}</p>
                        <p className="subteam-members">Members: {subteams.Business.members}</p>
                    </div>
                    <div className="subteam-content">
                        <img src={subteams.Engine.photo} className="subteam-photo" onClick={() => {setTeamModal(!teamModal); setTeamDescription(subteams.Engine.text); setTeamPhoto(subteams.Engine.photo); setTeamMembers(subteams.Engine.members); setTeamLead(subteams.Engine.lead); setTeamName(subteams.Engine.name)}}></img>
                        <p className="subteam-name">{subteams.Engine.name}</p>
                        <p className="subteam-lead">Director: {subteams.Engine.lead}</p>
                        <p className="subteam-members">Members: {subteams.Engine.members}</p>
                    </div>
                    <div className="subteam-content">
                        <img src={subteams.Fluids.photo} className="subteam-photo" onClick={() => {setTeamModal(!teamModal); setTeamDescription(subteams.Fluids.text); setTeamPhoto(subteams.Fluids.photo); setTeamMembers(subteams.Fluids.members); setTeamLead(subteams.Fluids.lead); setTeamName(subteams.Fluids.name)}}></img>
                        <p className="subteam-name">{subteams.Fluids.name}</p>
                        <p className="subteam-lead">Director: {subteams.Fluids.lead}</p>
                        <p className="subteam-members">Members: {subteams.Fluids.members}</p>
                    </div>
                    <div className="subteam-content">
                        <img src={subteams.Manufacturing.photo} className="subteam-photo" onClick={() => {setTeamModal(!teamModal); setTeamDescription(subteams.Manufacturing.text); setTeamPhoto(subteams.Manufacturing.photo); setTeamMembers(subteams.Manufacturing.members); setTeamLead(subteams.Manufacturing.lead); setTeamName(subteams.Manufacturing.name)}}></img>
                        <p className="subteam-name">{subteams.Manufacturing.name}</p>
                        <p className="subteam-lead">Director: {subteams.Manufacturing.lead}</p>
                        <p className="subteam-members">Members: {subteams.Manufacturing.members}</p>
                    </div>
                    <div className="subteam-content">
                        <img src={subteams.Safety.photo} className="subteam-photo" onClick={() => {setTeamModal(!teamModal); setTeamDescription(subteams.Safety.text); setTeamPhoto(subteams.Safety.photo); setTeamMembers(subteams.Safety.members); setTeamLead(subteams.Safety.lead); setTeamName(subteams.Safety.name)}}></img>
                        <p className="subteam-name">{subteams.Safety.name}</p>
                        <p className="subteam-lead">Director: {subteams.Safety.lead}</p>
                        <p className="subteam-members">Members: {subteams.Safety.members}</p>
                    </div>
                    <div className="subteam-content">
                        <img src={subteams.Vehicle.photo} className="subteam-photo" onClick={() => {setTeamModal(!teamModal); setTeamDescription(subteams.Vehicle.text); setTeamPhoto(subteams.Vehicle.photo); setTeamMembers(subteams.Vehicle.members); setTeamLead(subteams.Vehicle.lead); setTeamName(subteams.Vehicle.name)}}></img>
                        <p className="subteam-name">{subteams.Vehicle.name}</p>
                        <p className="subteam-lead">Director: {subteams.Vehicle.lead}</p>
                        <p className="subteam-members">Members: {subteams.Vehicle.members}</p>
                    </div>
                    <div className="subteam-content">
                        <img src={subteams.Agriculture.photo} className="subteam-photo" onClick={() => {setTeamModal(!teamModal); setTeamDescription(subteams.Agriculture.text); setTeamPhoto(subteams.Agriculture.photo); setTeamMembers(subteams.Agriculture.members); setTeamLead(subteams.Agriculture.lead); setTeamName(subteams.Agriculture.name)}}></img>
                        <p className="subteam-name">{subteams.Agriculture.name}</p>
                        <p className="subteam-lead">Director: {subteams.Agriculture.lead}</p>
                        <p className="subteam-members">Members: {subteams.Agriculture.members}</p>
                    </div>
                </div>
            </div>

            {/*Subteam Modal*/}
            {teamModal && (
                <div className="st-modal">
                    <div onClick={() => {setTeamModal(!teamModal)}} className="st-overlay" />
                    <div className="st-modal-content">
                        <div className="st-modal-box">
                            <img src={teamPhoto} className="st-modal-photo" />
                            <div className="st-modal-text">
                                <h1 className="st-name">{teamName}</h1>
                                <h1 className="st-lead">Director: {teamLead}</h1>
                                <p className="st-modal-paragraph">{teamDescription}</p>
                            </div>
                        </div>
                        <div className="st-members">
                            Members: {teamMembers}
                        </div>
                        <div className="st-close-modal" onClick={() => {setTeamModal(!teamModal)}}>x</div>
                    </div>
                </div>
            )}
            

            <Footer />
            <Modal isOpen={modalOpen} onClose={closeModal} project={selectedProject} />
        </div>
    );
};

export default ProjectsPage;
