import "./OurTeam.css"
import React from 'react'
import Chase from "./LPDT/MacDonnell, Chase/IMG_0301.jpg"
import Nathan from "./LPDT/O'brien, Nathan/IMG_0277.jpg"
import Jonner from "./LPDT/Delgado, David/IMG_0297.jpg"
import Jazmine from "./LPDT/Sandoval, Jazmine/IMG_0324.jpg"
import Nikhil from "./LPDT/Chitturi, Nikhil/IMG_0320.jpg"
import Lucca from "./LPDT/Fogaca, Lucca/IMG_0383.jpg"
import Ana from "./LPDT/Alvarez, Ana/anna.jpg"
import Austin from "./LPDT/Martin, Austin/austin-martin.jpg"
import Daniel from "./LPDT/Hoffman, Daniel/daniel hoffman.png"
import John from "./LPDT/Bromfield, John/john broomfield.jpg"
import Nicholas from "./LPDT/Pech, Nicholas/nick-Peck.png"

import Footer from "../Components/Footer"
//import { Button } from "@mui/material"
import people from './Information'

// import { TbBrandInstagram } from 'react-icons/tb';
// import { TbBrandTwitter } from 'react-icons/tb';
// import { TbBrandYoutube } from 'react-icons/tb';
// import { TbMail } from 'react-icons/tb';
// import { TbBrandLinkedin } from "react-icons/tb"
import { TbX } from 'react-icons/tb';
import { RiLinkedinBoxFill } from "react-icons/ri"
import { RiMailLine } from "react-icons/ri"


import { useState } from "react"
export default function OurTeam() {


  //code for the modal/popup to function
  const [modal, setModal] = useState(false);
  const [photo, setPhoto] = useState(Jonner);
  const [text, setText] = useState("Nothing has been loaded.")
  const [linkedin, setLinkedin] = useState("https://www.linkedin.com/")
  const [email, setEmail] = useState("mailto:ufliquidpropulsion@gmail.com")

  function toggleModal() {
    setModal(!modal);
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }


  return (
    <div>
      <div className="Hero4">
        <a className="HeroHeader2">Our Team</a>
        <a className="HeroText2">Florida Rocket Lab is led by a self-elected board of directors, each of whom has demonstrated dedication and leadership within their discipline.
        </a>
      </div>
      <div className="ContentBox">
        <div className="TeamContainer">
          <div className="TeamContent">
            <img src={Jonner} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Jonner.photo), setText(people.Jonner.text), setLinkedin(people.Jonner.linkedin), setEmail(people.Jonner.email)}}></img>
            <p className="TeamName">
              Jonner Delgado
            </p>
            <p className="TeamHeader">
              President
            </p>
          </div>
          <div className="TeamContent">
            <img src={Nikhil} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Nikhil.photo), setText(people.Nikhil.text), setLinkedin(people.Nikhil.linkedin), setEmail(people.Nikhil.email)}}></img>
            <p className="TeamName">
            Nikhil Chitturi
            </p>
            <p className="TeamHeader">
              Vice President of Propulsion 
            </p>
          </div>
          <div className="TeamContent">
            <img src={Chase} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Chase.photo), setText(people.Chase.text), setLinkedin(people.Chase.linkedin), setEmail(people.Chase.email)}}></img>
            <p className="TeamName">
              Chase MacDonnell
            </p>
            <p className="TeamHeader">
              Vice President of Avionics
            </p>
          </div>
          <div className="TeamContent">
            <img src={Ana} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Ana.photo), setText(people.Ana.text), setLinkedin(people.Ana.linkedin), setEmail(people.Jazmine.email)}}></img>
            <p className="TeamName">
              Ana Alvarez
            </p>
            <p className="TeamHeader">
            Chief Financial Officer
            </p>
          </div>
          <div className="TeamContent">
            <img src={Lucca} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Lucca.photo), setText(people.Lucca.text), setLinkedin(people.Lucca.linkedin), setEmail(people.Lucca.email)}}></img>
            <p className="TeamName">
              Lucca Fogaca
            </p>
            <p className="TeamHeader">
            Chief Safety Officer
            </p>
          </div>
          <div className="TeamContent">
            <img src={Austin} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Austin.photo), setText(people.Austin.text), setLinkedin(people.Austin.linkedin), setEmail(people.Austin.email)}}></img>
            <p className="TeamName">
              Austin Martin
            </p>
            <p className="TeamHeader">
              Engine Design Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={Daniel} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Daniel.photo), setText(people.Daniel.text), setLinkedin(people.Daniel.linkedin), setEmail(people.Daniel.email)}}></img>
            <p className="TeamName">
              Daniel Hoffman
            </p>
            <p className="TeamHeader">
              Fluids Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={Jazmine} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Jazmine.photo), setText(people.Jazmine.text), setLinkedin(people.Jazmine.linkedin), setEmail(people.Jazmine.email)}}></img>
            <p className="TeamName">
              Jazmine Sandoval
            </p>
            <p className="TeamHeader">
              Outreach Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={John} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.John.photo), setText(people.John.text), setLinkedin(people.John.linkedin), setEmail(people.John.email)}}></img>
            <p className="TeamName">
              John Bromfield
            </p>
            <p className="TeamHeader">
              Vehicle Engineering Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={Nathan} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Nathan.photo), setText(people.Nathan.text), setLinkedin(people.Nathan.linkedin), setEmail(people.Nathan.email)}}></img>
            <p className="TeamName">
              Nathan O&apos;Brien
            </p>
            <p className="TeamHeader">
              Business Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={Nicholas} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Nicholas.photo), setText(people.Nicholas.text), setLinkedin(people.Nicholas.linkedin), setEmail(people.Nicholas.email)}}></img>
            <p className="TeamName">
              Nicholas Pech
            </p>
            <p className="TeamHeader">
              Manufacturing Director
            </p>
          </div>
        </div>
      </div>

      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="modal-box">
              <img src={photo} className="modal-photo"></img>
              <div className="modal-text">
                <p className="modal-paragraph">
                  {text}
                </p>
                {/*<p className="modal-paragraph">
                  Projects:
                </p>

                <p className="modal-paragraph">
                  &#x2022; Lander Damping Mechanism  <br></br>
                  &#x2022; Engine Gimbal System  <br></br>
                  &#x2022; Sponsor Initiative <br></br>
      </p>*/}

                {/*<Button variant="contained" sx={{
                  backgroundColor: "transparent",
                  border: "3px solid grey",
                  borderRadius: 8,
                  align: "center",
                  width: "260px",
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  marginTop: "20px",
                  marginBottom: "20px",
                  color: "gray",
                  '&:hover': {
                    backgroundColor: '#4484b4',
                    color: 'white',
                  }
                }}
                  // onClick = {() => {
                  //     window.location.href("https://www.ufl.edu/")
                  // }}

              >Download Portfolio</Button>*/}




                <div className="modal-socials">


                  <a className="Twitch" href={linkedin} target="_blank" rel="noreferrer">
                    <RiLinkedinBoxFill className="IconText2" size="48px"></RiLinkedinBoxFill>
                  </a>
                  <a className="Twitch" href={email} target="_blank" rel="noreferrer">
                    <RiMailLine className="IconText2" size="48px"></RiMailLine>
                  </a>

                </div>


              </div>
            </div>
            <div className="close-modal" onClick={toggleModal}>
              <TbX className='close-modal-text' />
            </div>
          </div>
        </div>
      )}

      <Footer></Footer>
    </div>
  );
}