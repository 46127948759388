import * as React from 'react';
import "./Resources.css";
import Footer from '../Components/Footer';
import mattermost from "../Images/mattermost-logo.png";
import microsoft from "../Images/microsoft-teams-logo.png";
import slack from "../Images/Slack-logo.png";



export default function Resources() {
    return (
        <div>
            <div className="Hero2">
                <a className="HeroHeader2">Resources</a>
                <a className="HeroText2">Welcome to our community! If you are new here, feel free to join our Slack channel to get connected. Existing members who need access to the resources listed below should reach out to their team lead.</a>
            </div>
            <div className="resources-container">
            <div className="resources-grid">
                {/* Slack - Assuming you want to add Slack */}
                <a href="https://join.slack.com/t/flrocketlab/shared_invite/zt-2gv497mia-TL9BGCsuz3WrxeVvAAxm5w" className="resource-card slack" target="_blank" rel="noopener noreferrer">
                    <img src={slack} alt="Slack" className="resource-image"/>
                    <h2 className="resource-name">Slack</h2>
                </a>
                {/* Confluence */}
                <a href="https://floridarocketlab.atlassian.net/wiki/home" className="resource-card confluence" target="_blank" rel="noopener noreferrer">
                    <img src="https://pbs.twimg.com/profile_images/1022908662392619008/5_z16TbH_400x400.jpg" alt="Confluence" className="resource-image"/>
                    <h2 className="resource-name">Confluence</h2>
                </a>
                {/* Mattermost */}
                <a href="https://chat.floridarocketlab.org/" className="resource-card mattermost" target="_blank" rel="noopener noreferrer">
                    <img src={mattermost}/>
                    <h2 className="resource-name">Mattermost</h2>
                </a>
                {/* Jiraboard */}
                <a href="https://floridarocketlab.atlassian.net/jira" className="resource-card jiraboard" target="_blank" rel="noopener noreferrer">
                    <img src="https://store-images.s-microsoft.com/image/apps.60333.972ef998-9cea-47ce-b06b-160ef818806e.9fd711c5-d061-4a7d-bfaa-456676dfba0c.8a3db838-2bc4-47a6-bfdb-316a3aea1bf7.png" alt="Jiraboard" className="resource-image"/>
                    <h2 className="resource-name">Jira</h2>
                </a>
                {/* Teams */}
                <a href="https://uflorida.sharepoint.com/teams/LiquidPropulsionDevelopmentTeam" className="resource-card teams" target="_blank" rel="noopener noreferrer">
                    <img src={microsoft} alt="Teams" className="resource-image"/>
                    <h2 className="resource-name">Teams</h2>
                </a>
            </div>
        </div>
            <Footer/>
        </div>
    );
}