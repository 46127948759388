import * as React from 'react';
import "./Sponsors.css"
//import { Button } from '@mui/material';
import Footer from "../Components/Footer";
import MWLogo from "./Carousel/Meridian_Waste_Logo.png";
import UFLogo from "./Carousel/UF_Monogram.png";
import AnsysLogo from "./Carousel/ANSYS_logo.png";


export default function Sponsors() {

    return (

        <div>
            <div className="Hero6">
                <a className="HeroHeader2">Our Sponsors</a>
                <a className="HeroText2">We would like to extend our heartfelt thanks to our generous contributors. Your support is what gets us closer to space.</a>
            </div>
            <div className="SponsorIntroBox">
                <div className="SponsorContainer">
                    <div className="SponsorRow">
                        <img src={AnsysLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">Ansys - Bluefire Tier</p>
                            <p className = "SponsorText">Ansys offers a comprehensive software suite that spans the entire range of physics, providing access to virtually any field of engineering simulation that a design process requires.</p>
                        </div>
                    </div>
                    <div className="SponsorRow">
                        <img src={UFLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">UF MAE - Bluefire Tier</p>
                            <p className = "SponsorText">The Department of Mechanical and Aerospace Engineering  at the University of Florida is responsible for a multitude of undergraduate and graduate degrees in various engineering disciplines.</p>
                        </div>
                    </div>
                    <div className="SponsorRow">
                        <img src={MWLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">Meridian Waste - Bluefire Tier</p>
                            <p className = "SponsorText">Meridian Waste is a trash collection and recycling company with a less wasteful approach to garbage. They provide convenient curbside waste removal services that you can rely on.</p>
                        </div>
                    </div>
                </div>

            </div>


            <Footer></Footer>
        </div>
    );
}