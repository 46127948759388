import ChasePhoto from "./LPDT/MacDonnell, Chase/IMG_0301.jpg"
import NathanPhoto from "./LPDT/O'brien, Nathan/IMG_0277.jpg"
import JonnerPhoto from "./LPDT/Delgado, David/IMG_0297.jpg"
import JazminePhoto from "./LPDT/Sandoval, Jazmine/IMG_0324.jpg"
import NikhilPhoto from "./LPDT/Chitturi, Nikhil/IMG_0320.jpg"
import LuccaPhoto from "./LPDT/Fogaca, Lucca/IMG_0383.jpg"
import AnaPhoto from "./LPDT/Alvarez, Ana/anna.jpg"
import AustinPhoto from "./LPDT/Martin, Austin/austin-martin.jpg"
import DanielPhoto from "./LPDT/Hoffman, Daniel/daniel hoffman.png"
import JohnPhoto from "./LPDT/Bromfield, John/john broomfield.jpg"
import NicholasPhoto from "./LPDT/Pech, Nicholas/nick-Peck.png"

const people = {
    Chase: {
        photo: ChasePhoto,
        text: "Chase MacDonnell is a third-year computer science major serving as the Vice President of Avionics for the Florida Rocket Lab. As a VP, he has played a crucial role in designing a robust and reliable control system for the Sparrow Engine and coordinating its integration across all sub-teams. Outside of FRL, he enjoys mentoring in the FIRST Robotics Competition and working on personal projects.",
        linkedin: "https://www.linkedin.com/in/chase-macdonnell/",
        email:"mailto:chase.macdonnell@floridarocketlab.org"
    },

    Nathan: {
        photo: NathanPhoto,
        text: "Nathan O`Brien is a fourth-year aerospace engineering major serving as the Business Development Director for the Florida Rocket Lab. In this role, he has overseen the founding of the FRL brand and created a private funding pipeline that will serve the team for years to come. He is directly responsible for maintaining university and corporate relations and has led the development of all external engagement tools such as this website. As a space junkie, he hopes to one day apply his skills to enable further commercialization and technology transfer of space-related advancements.",
        linkedin: "https://www.linkedin.com/in/nathano-brien/",
        email:"mailto:nathanobrien261@gmail.com"
    },

    Jonner: {
        photo: JonnerPhoto,
        text: "Jonner Delgado is a fourth-year aerospace engineering major. As a member of FRL he has spent the last 3 years serving as the Testing Director and President. David leads as president by working and assisting with every sub-team so that the teams’ overarching goal is achieved. More recently David has begun leading FRL’s newest team which is designing and building our future landing rocket. Outside of FRL, David enjoys film and video production where he mentors high school students in his community.",
        linkedin: "https://www.linkedin.com/in/davidjonnerdelgado/",
        email:"mailto:david.delgado@ufl.edu"
    },

    Jazmine: {
        photo: JazminePhoto,
        text: "The Public Relations Director manages all social media outlets to ensure current and potential new members are up to date on Liquid Propulsion’s events and endeavors. The position is currently held by Jazmine Sandoval, a fourth-year mechanical engineering major. Jazmine has been able to increase the amount of engagement throughout all platforms by creating consistent content. Apart from Liquid Propulsion, she is actively involved in the Society of Women Engineers and recently took part in Survivor: Florida.",
        linkedin: "https://www.linkedin.com/in/jazmine-sandoval/",
        email:"mailto:jazminesandoval@ufl.edu"
    },
    
    Nikhil: {
        photo: NikhilPhoto,
        text: "As Vice President of Propulsion, Nikhil’s responsibilities include coordinating the development of our rocket engines from napkin sketches to high performance machines and integrating them with other subsystems. You can usually find him leading design reviews with the team or in the garage assembling hardware. In between semesters, he tutors high schoolers to learn calculus and helps them prepare for the transition to college life.",
        linkedin: "https://www.linkedin.com/in/nikhil-chitturi/",
        email:"mailto:ufliquidpropulsion@gmail.com"
    },

    Lucca: {
        photo: LuccaPhoto,
        text: "Lucca Fogaca is a fourth-year student pursuing a dual degree in Mechanical and Aerospace Engineering who serves as the Chief Safety Officer (CSO) of LDPT. He is responsible for leading the safety sub-team that manages all matters concerning systems safety, as well as general mission assurance. With over three years of experience in Logistics & Sustainment Engineering at Lockheed Martin, he possesses the experience and knowledge necessary to apply strict industry and military standards which elevates the FRLs ability to design, build, and test increasingly more powerful and complex systems.",
        linkedin: "https://www.linkedin.com/in/lucca-fogaca-92170121b/",
        email:"mailto:ufliquidpropulsion@gmail.com"
    },

    Ana: {
        photo: AnaPhoto,
        text: "Ana Alvarez",
        linkedin: "https://www.linkedin.com/in/ana-alvarez-3985501b3/",
        email: "mailto:ufliquidpropulsion@gmail.com"
    },    

    Austin: {
        photo: AustinPhoto,
        text: "Austin Martin",
        linkedin: "https://www.linkedin.com/in/austin-m-martin/",
        email: "mailto:ufliquidpropulsion@gmail.com"
    },

    Daniel: {
        photo: DanielPhoto,
        text: "Daniel Hoffman",
        linkedin: "https://www.linkedin.com/in/danieljhoffman1/",
        email: "mailto:ufliquidpropulsion@gmail.com"
    },

    John: {
        photo: JohnPhoto,
        text: "John Broomfield",
        linkedin: "https://www.linkedin.com/in/john-bromfield-ufl/",
        email: "mailto:ufliquidpropulsion@gmail.com"
    },

    Nicholas: {
        photo: NicholasPhoto,
        text: "Nicolas Pech",
        linkedin: "https://www.linkedin.com/in/nicholas-i-pech/",
        email: "mailto: ufliquidpropulsion@gmail.com"
    }

}

export default people;